import moment from "moment";
import ReactDOM from "react-dom/client";
import React, { useState, useEffect } from "react";
import MonthlySatta from "./monthlySattaTable";
import Select, { MultiValue } from "react-select";
import { Alert } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let nextmMonthDate = moment(new Date()).add(1, "months");
let nextMonthData,
  preMonthData,
  prevDate = moment(new Date()).subtract(1, "months");
let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
let monthFullName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function FooterData() {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "46px",
      height: "46px",
    }),
    input: (provided, state) => ({ ...provided, minHeight: "46px" }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "46px",
      padding: "0 8px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      height: "46px",
      display: "flex",
      alignItems: "center",
    }),
    indicatorsContainer: (provided, state) => ({ ...provided, height: "46px" }),
  };

  const [data, setData] = useState();
  const [predata, setDataPre] = useState();
  const [selecteData, sendData] = useState();
  const [sendDate, sndDate] = useState();
  const [sendList, sndList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selecYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  const handleChangeYear = (selecYear) => {
    setSelectedYear(selecYear);
  };
  const handleChangeMonth = (selectedMonth) => {
    setSelectedMonth(selectedMonth);
  };

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getGameName", {
      method: "POST", // or 'PUT' depending on your requirements
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((json) => sndList(json))
      .catch((error) => console.error(error));
  }, []);

  preMonthData = "" + prevDate.format("MMM-yyyy");

  function prevMonth() {
    let preData = moment(prevDate).add(1, "months");
    let data = {
      month: preData.month() == 0 ? 12 : preData.month(),
      year: preData.year(),
      days: moment(
        preData.year() + "-" + (preData.month() == 0 ? 12 : preData.month())
      ).daysInMonth(),
      gameName: "",
    };
    apiData(data);
    sndDate({
      date: months[data.month - 1] + "-" + data.year,
    });
    prevDate = moment(prevDate).subtract(1, "months");
    preMonthData = "" + prevDate.format("MMM-yyyy");
    setDataPre(preMonthData);
    nextmMonthDate = moment(nextmMonthDate).subtract(1, "months");
    nextMonthData = "" + nextmMonthDate.format("MMM-yyyy");
    setData(nextMonthData);
  }

  nextMonthData = "" + nextmMonthDate.format("MMM-yyyy");
  // console.log('nextMonthData', nextMonthData)
  function nextMonth() {
    nextmMonthDate = moment(nextmMonthDate).add(1, "months");

    let data = {
      month: nextmMonthDate.month() == 0 ? 12 : nextmMonthDate.month(),
      year: nextmMonthDate.year(),
      days: moment(
        nextmMonthDate.year() +
          "-" +
          (nextmMonthDate.month() == 0 ? 12 : nextmMonthDate.month())
      ).daysInMonth(),
      gameName: "",
    };
    // console.log(data)
    apiData(data);
    sndDate({
      date: months[data.month - 1] + "-" + data.year,
    });
    nextMonthData = "" + nextmMonthDate.format("MMM-yyyy");
    setData(nextMonthData);
    prevDate = moment(prevDate).add(1, "months");
    preMonthData = "" + prevDate.format("MMM-yyyy");
    setDataPre(preMonthData);
  }
  function apiData(params) {
    fetch("https://api.sattakingvip.co.in/getmonthdata", {
      method: "POST", // or 'PUT' depending on your requirements
      headers: {
        "Content-Type": "application/json", // specify the content type
      },
      body: JSON.stringify({
        month: params.month,
        year: params.year,
        gameName: params.gameName ? params.gameName : "",
        result: "",
        days: params.days,
      }),
    })
      .then((response) => response.json())
      .then((json) => sendData(json))
      .catch((error) => console.error(error));
  }

  // let date = new Date();

  function getAllGameDetails() {
    // console.log(selectedMonth)
    if (selectedMonth != null && selecYear != null && selectedOption != null) {
      let allData = {
        month: selectedMonth.value ? selectedMonth.value : "",
        year: selecYear.value ? selecYear.value : "",
        gameName: selectedOption.value ? selectedOption.value : "",
        result: "",
        days: moment(selecYear.value + "-" + selectedMonth.value).daysInMonth(),
      };

      apiData(allData);
      sndDate({
        date: months[allData.month - 1] + "-" + allData.year,
      });
    } else {
      // return toast("All Field Requied");
      return toast.error("All Field Requied");
    }
  }

  const currentYear = new Date().getFullYear();
  let arr = [];
  for (let year = currentYear - 6; year <= currentYear; year++) {
    arr.push({ value: year, label: year });
  }
  let monthArray = [];
  for (let i = 0; i < monthFullName.length; i++) {
    monthArray.push({ value: i + 1, label: monthFullName[i] });
  }

  return (
    <div className="footerData">
      <MonthlySatta message={selecteData} dateData={sendDate} />
      <div className="table-button">
        <div className="blue-button">
          <div className="col-12">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-xs-12">
                <div className=" form-control">
                  <button
                    value={prevDate}
                    className="btn-primary"
                    onClick={prevMonth}
                  >
                    {predata ? predata : preMonthData}
                  </button>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12 "></div>
              <div className="col-md-4 col-sm-4 col-xs-12 float-end">
                <div className=" form-control">
                  <button
                    value={nextmMonthDate}
                    className="btn-primary"
                    id="nextMonth"
                    onClick={nextMonth}
                  >
                    {data ? data : nextMonthData}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="long-button">
          <a href="">
            <button className="col-md-11 col-sm-10 col-xs-10">
              June, 2024 Ka Gali, Desawar, Ghaziabad Aur Faridabad Ka Latest
              Chart Dekhne Ke Liye Yahan Click Kariye
            </button>
          </a>
        </div>
      </div>

      {/* // <!-- bottom container HTML from here --> */}

      <div className="yellow-container">
        <div className="bottom-container">
          <h1>
            Yahan Aap Month Aur Year Select Karke Gali, Desawar, Ghaziabad Aur
            Faridabad Ka Combined Chart Dekh Sakte Hai.
          </h1>
          <div className="selection-container">
            <div className="col-12">
              <div className="row">
                <div className="col-md-3 col-sm-12 col-xs-12 pb-2">
                  <Select
                    menuPlacement="auto"
                    value={selectedMonth}
                    onChange={handleChangeMonth}
                    options={monthArray}
                    placeholder="Select Month"
                    styles={customStyles}
                  />
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12 pb-2">
                  <Select
                    menuPlacement="auto"
                    value={selecYear}
                    onChange={handleChangeYear}
                    options={arr}
                    placeholder="Select Year"
                    styles={customStyles}
                  />
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12 pb-2">
                  <Select
                    menuPlacement="auto"
                    value={selectedOption}
                    onChange={handleChange}
                    options={sendList}
                    placeholder="Select Game"
                    styles={customStyles}
                  />
                </div>
                <div className="col-md-3 col-sm-12 col-xs-12 pb-2">
                  <button
                    className="form-control"
                    id="go-button"
                    onClick={getAllGameDetails}
                  >
                    Go
                  </button>
                  <ToastContainer
                    autoClose={2000}
                    position="top-right"
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick
                    pauseOnHover
                    draggable={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a href="/">
        <button className="float-end refreshButton">Referesh</button>
      </a>
      {/* <a ><button className="float-end refreshButton">Referesh</button><a /> */}
    </div>
  );
}
export default FooterData;
